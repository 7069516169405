import * as modelInterface from './system-health.interface';

/// <reference path="./system-health.interface.ts" />
export namespace Operator {
  export class SystemHealth implements modelInterface.Operator.ISystemHealth {
    readonly type = 'system_health';


    constructor(public id,
                public cppStatus: boolean,
                public pvmsStatus: boolean,
                public criticalQueueSize: number,
                public defaultQueueSize: number,
                public lowQueueSize: number,
                public dmsStatus: boolean,
                public customerQueueSize: number,
                public dmsUploadQueueSize: number,
                public dmsCleanUpQueueSize: number,
                public dmsSyncQueueSize: number,
                public emailQueueSize: number,
                public pushNotificationsQueueSize: number) {
    }
  }
}
