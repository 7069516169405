import {DocumentType} from '../process-artifact/process-artifact';
import {IResource} from '../../lib/fivef-net/fivef-api-resource/models/resource.interface';
import {ApplicableState} from '../cac/cac.interface';
import {BomDataNodeTableColumnType} from '../../lib/fivef-ui/bom/fivef-node-details/fivef-node-settings.interface';
import {IBomNodeColumnVisibility} from '../process-management/process-management';

/**
 * Valid parameters for BOM nodes.
 * Used for PUT/POST calls.
 */
export interface IBomDataNodeParams {
  title: string;
  description?: string;
  color?: string;
  order?: number;
  preDueDate?: Date;
  dueDate?: Date;
  startsAt?: Date;
  endsAt?: Date;
  startedAt?: Date;
  completedAt?: Date;
  priority?: string;
  documentType?: DocumentType;
  categoryId?: string;
  year?: number;
  month?: number;
  important?: boolean;
  low_hanging_fruit?: boolean;
  effort?: number;
  progress?: number;
  sum?: number;
  applicable?: boolean;
  annotation?: string;
  choice?: number;
  choiceOptions?: [];
  multiChoice?: number[];
  archived_at?: Date;
}

/**
 * Bom node data interface.
 */
export interface IBomDataNode extends IResource {
  id: string;
  title: string;
  color?: string;
  description: string;

  /**
   * Direct item Response.
   */
  response?: string;

  /**
   * Toggle if item response is enabled.
   */
  responseEnabled?: boolean;

  /**
   * Pre due date as reminder.
   * Concept taken from laywer software.
   * Currently unused.
   */
  preDueDate?: Date,

  /**
   * Regular due date for BOM node items.
   */
  dueDate: Date;

  /**
   * Assessment period. Year.
   */
  year?: number;

  /**
   * Assessment period. month.
   */
  month?: number;

  /**
   * GANTT logic: Item really started at date.
   */
  startedAt?: Date;

  /**
   * GANTT logic: Date when item should start.
   */
  startsAt?: Date;

  /**
   * GANTT logic:
   * Date when item was completed.
   */
  completedAt?: Date;

  /**
   * GANTT logic:
   * Date when item should end.
   */
  endsAt?: Date;

  /**
   * Status of an BOM node item.
   */
  status?: string;

  /**
   * Locked for user actions, e.g. upload.
   */
  lockedAt?: Date;

  /**
   * Priority of an BOM node item.
   */
  priority?: string;

  /**
   * Conveniance marker if item is active.
   * Used at CAC.
   */
  checked?: boolean;

  /**
   * Conveniance marker if item is closed.
   * Used at CAC.
   */
  closed?: boolean;

  /**
   * Marker if item is applicable for use case or purpose.
   * Used at CAC and Collecto as additional column.
   * Valid values are NULL (unset), true or false.
   */
  applicable?: ApplicableState;

  /**
   * Marker if an item is a "low hanging fruit" and as such a
   * simple task.
   */
  low_hanging_fruit?: boolean;

  /**
   * Sum, decimal.
   */
  sum?: number;

  /**
   * Effort, integer, unit less.
   */
  effort?: number;

  /**
   * Marker if an item is an important task.
   */
  important?: boolean;

  /**
   * Progress. Should be between 0-100 to be evaluated as percent.
   */
  progress?: number;

  /**
   * Simple annotation. Free text field.
   */
  annotation?: string

  /**
   * Choice, integer. Should be the index of the selected choice option.
   * Valid values are NULL (unset), integer..
   */
  choice?: number;

  /**
   * Options to be selected for element.
   * Default is GENERAL.YES/NO to be translatable.
   */
  choiceOptions?: string[];

  /**
   * Conveniance accessor of the overdue state.
   * Must be established by builder.
   */
  overdued?: boolean;

  /**
   * Participation ID if assigned.
   */
  responsibleId?: string;

  /**
   * Predefined document type of node.
   */
  documentType?: DocumentType;

  /**
   * Parent node.
   */
  parentId?: string;

  createdAt?: Date;
  updatedAt?: Date;

  /**
   * Referenced message/INBOX notification ID of node if new.
   */
  newRecordMessage?: string;

  clone(): IBomDataNode;
}

/**
 * Known data node types of workflows aka Bom::Tree::Node.
 */
export enum BomDataNodeType {
  CollectorItemNode = 'collector_item_node',
  CollectorCategory = 'collector_category_node',
  QuickshareDataNode = 'quickshare_data_node',
  DataNode = 'node',
}

/**
 * BOM node attachment model.
 * Attachments are a temporary upload model before items are transmitted into the DMS.
 * As such BOM attachments must be considered as volatile.
 */
export interface IBomDataNodeAttachment extends IResource {
  id: string;
  displayName: string;
  filename: string;
  mimeType: string;
  size: number;
  description: string;
  uploadedBy: string;
  revision?: string;
  fingerprint: string;
  referenceId: string;
  content?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export type BomNodeHeaderColumnType = 'spacer'
    | 'colorHeader'
    | 'important-header'
    | 'low_hanging_fruit-header'
    | 'iconHeader'
    | 'titleHeader'
    | 'uploadHeader'
    | 'dueDateHeader'
    | 'responsibleHeader'
    | 'statsHeader'
    | 'priorityHeader'
    | 'applicable-header'
    | 'effort-header'
    | 'progress-header'
    | 'sum-header'
    | 'annotation-header'
    | 'choice-header'
    | 'actionsHeader';

export type BomNodeColumnType = BomDataNodeTableColumnType;

/**
 * Columns setup for a Bom Node compatible workflows, e.g. the Collecto listing or CAC.
 */
export interface IBomNodeColumnSetup {
  header: BomNodeHeaderColumnType[];
  columns: BomNodeColumnType[];

  /**
   * Returns the visibility of a column: internal_only or all.
   * This enables the view to show a lock icon if a column is only visible to members of the office.
   */
  visibilityMap: { [column: string]: IBomNodeColumnVisibility };
}

/**
 * Default descriptions for Bom Node properties.
 */
export enum BomNodePropertyDescription {
  color = 'GENERAL.COLOR',
  important = 'TASK.IMPORTANT_DESC',
  low_hanging_fruit = 'TASK.LOW_HANGING_FRUIT_DESC',
  icon = 'Icon',
  title = 'GENERAL.TITLE_DESC',
  upload = 'COLLECTOR.UPLOAD_ROW_ELEMENT_DESC',
  due_date = 'COLLECTOR.DUE_DATE_ROW_DESC',
  responsible = 'COLLECTOR.RESPONSIBLE_ROW_DESC',
  status = 'GENERAL.STATUS',
  priority = 'GENERAL.PRIORITY',
  applicable = 'COLLECTOR.APPLICABLE_DESC',
  effort = 'COLLECTOR.EFFORT_DESC',
  progress = 'COLLECTOR.PROGRESS_DESC',
  sum = 'COLLECTOR.SUM_DESC',
  annotation = 'GENERAL.ANNOTATION_DESC',
  choice = 'COLLECTOR.CHOICE_DESC',
  actions = 'PROJECT_ROOM.ACTIONS',
}

export enum BomNodePropertyName {
  color = 'GENERAL.COLOR',
  important = 'INBOX.IMPORTANT',
  low_hanging_fruit = 'TASK.LOW_HANGING_FRUIT',
  icon = 'Icon',
  title = 'GENERAL.TITLE',
  upload = 'Upload',
  due_date = 'GENERAL.DUE_DATE',
  responsible = 'GENERAL.RESPONSIBLE',
  status = 'GENERAL.STATUS',
  priority = 'GENERAL.PRIORITY',
  applicable = 'WORKFLOWS.STATUS.APPLICABLE',
  effort = 'GENERAL.EFFORT',
  progress = 'GENERAL.PROGRESS',
  sum = 'GENERAL.SUM',
  annotation = 'GENERAL.ANNOTATION',
  choice = 'GENERAL.CHOICE',
  actions = 'PROJECT_ROOM.ACTIONS',
}
