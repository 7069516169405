<form class="fivef-tfa-factor-verification--container" [formGroup]="form">
  <div class="fivef-tfa-factor-verification--preface mb-1">
    <h2 *ngIf="showTitle">{{ 'AUTH.TWO_FACTOR_COMP_TITLE' | translate }}</h2>

    <p>{{ 'AUTH.VERIFY_COMPONENT_SUBTITLE' | translate }}
      <a href="#" (click)="$event.preventDefault(); sendSMS()"> {{ 'AUTH.VERIFY_COMPONENT_SEND' | translate }} </a>
    </p>
  </div>

  <div class="fivef-tfa-factor-verification--input-container">
    <mat-form-field class="fivef-tfa-factor-verification--input-field">
      <input matInput #firstInput formControlName="firstInput" maxlength="1" autocomplete="off"
             (keyup.enter)="onSubmit?.emit()"
             (click)="setCaretPosition($event)" autocapitalize="none"/>
    </mat-form-field>
    <mat-form-field class="fivef-tfa-factor-verification--input-field">
      <input matInput #secondInput formControlName="secondInput" maxlength="1" autocomplete="off"
             (keyup.enter)="onSubmit?.emit()"
             (keydown.backspace)="onBackspace($event, 2)" (click)="setCaretPosition($event)" autocapitalize="none"/>
    </mat-form-field>
    <mat-form-field class="fivef-tfa-factor-verification--input-field">
      <input matInput #thirdInput formControlName="thirdInput" maxlength="1" autocomplete="off"
             (keyup.enter)="onSubmit?.emit()"
             (keydown.backspace)="onBackspace($event, 3)" (click)="setCaretPosition($event)" autocapitalize="none"/>
    </mat-form-field>
    <mat-form-field class="fivef-tfa-factor-verification--input-field">
      <input matInput #fourthInput formControlName="fourthInput" maxlength="1" autocomplete="off"
             (keyup.enter)="onSubmit?.emit()"
             (keydown.backspace)="onBackspace($event, 4)" (click)="setCaretPosition($event)" autocapitalize="none"/>
    </mat-form-field>
    <mat-form-field class="fivef-tfa-factor-verification--input-field">
      <input matInput #fifthInput formControlName="fifthInput" maxlength="1" autocomplete="off"
             (keyup.enter)="onSubmit?.emit()"
             (keydown.backspace)="onBackspace($event, 5)" (click)="setCaretPosition($event)" autocapitalize="none"/>
    </mat-form-field>
    <mat-form-field class="fivef-tfa-factor-verification--input-field">
      <input matInput #sixthInput formControlName="sixthInput" maxlength="1" autocomplete="off"
             (keyup.enter)="onSubmit?.emit()"
             (keydown.backspace)="onBackspace($event, 6)" (click)="setCaretPosition($event)" autocapitalize="none"/>
    </mat-form-field>

    <!-- <div class="button-container">
      <button mat-raised-button color="primary" class="fullwidth" mat-button type="button" >
        {{ 'AUTH.SEND_BY_SMS_BUTTON' | translate }}
      </button>
    </div> -->
  </div>

  <mat-error *ngIf="error?.length > 0">{{ 'AUTH.INVALID_OTP_CODE' | translate }}</mat-error>
</form>
